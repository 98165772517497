import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Mega Fortune`}</h1>
    <p>{`Mega Fortune är en luxuös 5-hjuls, 25-spellinjer video slot med den största online progressiva jackpotten av alla video slots som finns ute på nätet. Det lyxiga temat av videoslotet ger dig som spelare en antydan om vad som väntar när du vinner den livsförändrande Mega jackpoten. Utöver den sammanslagna Mega Jackpot som har ett förväntat värde på över 40.000.000kr så har spelet också två lokala jackpots som man kan vinna. Tre symboler med bonushjulet aktivera spelets Jackpot bonusspel där du som spelare snurrar lyckohjulet där man antingen vinna någon av de jackpots eller ett mynt pris. Utöver de jackpots som är i spelet så finns det också en gratis spin bonus med upp till gånger 5 i multiplikation på vinster.`}</p>
    <h2>{`Världens största ut betalda online Jackpot`}</h2>
    <p>{`Mega Fortune är den videoslot som har betalt ut den största jackpoten online någonsin på ett online Casino. Det var en lycklig norman som vann den på Betsson för hela 109 miljoner kronor, för att göra det hela ännu mera otroligt så vann han jackpoten utan att spendera en enda krona då han hade 5 free spins på sitt konto som han fick igenom en kampanj som Betsson erbjöd när jackpoten var som störst. Med dessa free spins så lyckas han vinna lite bonus pengar som han använder för att vinna den mega stora jackpoten på 109 miljoner kronor.`}</p>
    <h2>{`Hur man spelar Mega Fortune`}</h2>
    <p>{`Mega Fortune är precis som alla andra videoslots väldigt enkla att spela på. Det enda som man behöver göra är att välja hur många rader som man vill spela på samt vilket myntvärde man vill satsa per rad, till exempel om du spelar på 10 rader och har ett myntvärde på 1kr så kommer du att satsa 10kr totallt.`}</p>
    <h2>{`Mega Fortune Autospel`}</h2>
    <p>{`Autospel är en funktion som du kan aktivera så att automaten spelar av sig själv utan att du behöver klicka på starta en ny spelrunda. Det går att välja mellan 10 till 1000 rundor på autospel, innan man starta autospel så har man flera inställningar som man kan aktivera, dessa är att man kan välja att automaten stannar vid vinst, om free spins vinns, om bonusspelet vinns, när en enstaka vinst översiger valt belopp, om saldor ökat med önskat belopp, om saldot minstat med oönskat belopp.`}</p>
    <h2>{`Mega Fortune Wild`}</h2>
    <p>{`Wild symbolen i Mega Fortune ersätter alla symboler utom scatter och bonussymboler. Skulle du till exempel ha 2 symboler eftervarandra på en spelrad varav den trejde är en wild så kommer wild symbolen göra att det blir 3 symboler i rad.`}</p>
    <h2>{`Mega Fortune Bonusspel`}</h2>
    <p>{`För att komma in i Mega Fortune bonusspel så krävs det att man får 3 bonusspel symboler i rad på en satsad spelrad. När man har två bonusspel symboler och kan få en tredje som startar bonusspelet så börja hela automaten att snurra snabbt med ett svischande ljud.
När man är inne i bonusspelet så snurrar man på ett stort lyckohjul som kan ge dig en mindre vinst på runt 50-100 mynt men det kan även ta dig vidare till steg två som är det andra lyckohjulet där man kan vinna mellan 200-700 mynt och även där så kan man ta sig vidare till ännu ett lyckhjul där man kan vinna Mega Jackpoten, Midi Jackpoten, Rapid Jackpoten eller 2500-7500 mynt.`}</p>
    <h2>{`Mega Fortune Scatter`}</h2>
    <p>{`Får man 3 eller flera scatter symboler med champagneflaskan på skärmen efter en avslutad spelrunda så får man välja en av scatter symbolerna som kan ge dig upp till 20 free spins med ett värde som kan multipliceras upp till gånger 5.
Får man två eller flera scatter symboler under en free spin runda så kan man få extra många free spins eller en högre multipler-
Värdet på scatter symbolerna är som följande.
5 – 100 mynt
4 – 10 mynt
3 – 3 mynt
2- 2 mynt`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      